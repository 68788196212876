body.admin {
    .report-container {
        clear: both;
        display: block;
    }
}

.admin .contentTabs li span a {
    color: #4c4c4c;
}
